{
  "name": "@lottiefiles/dotlottie-web",
  "version": "0.22.0",
  "type": "module",
  "description": "Lottie and DotLottie player for the web",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/LottieFiles/dotlottie-web.git",
    "directory": "packages/web"
  },
  "homepage": "https://github.com/LottieFiles/dotlottie-web#readme",
  "bugs": "https://github.com/LottieFiles/dotlottie-web/issues",
  "author": "LottieFiles",
  "contributors": [
    "Abdelrahman Ashraf <a.theashraf@gmail.com>"
  ],
  "license": "MIT",
  "main": "dist/index.cjs",
  "module": "dist/index.js",
  "types": "dist/index.d.ts",
  "files": [
    "dist"
  ],
  "keywords": [
    "dotlottie",
    "lottie",
    "player",
    "animation",
    "web",
    "canvas",
    "javascript",
    "thorvg"
  ],
  "scripts": {
    "build": "tsup",
    "dev": "tsup --watch",
    "lint": "eslint --fix .",
    "stats:eslint": "cross-env TIMING=1 eslint .",
    "stats:ts": "tsc -p tsconfig.build.json --extendedDiagnostics",
    "test": "vitest run --browser.headless",
    "test:coverage": "vitest run --browser.headless --coverage",
    "test:watch": "vitest",
    "type-check": "tsc --noEmit"
  },
  "devDependencies": {
    "@types/node": "^20.10.5",
    "@vitest/browser": "^1.2.2 ",
    "@vitest/coverage-istanbul": "^1.2.2",
    "cross-env": "7.0.3",
    "tsup": "8.0.1",
    "typescript": "5.0.4",
    "vitest": "^1.2.2",
    "webdriverio": "^8.32.0"
  },
  "publishConfig": {
    "access": "public"
  }
}
